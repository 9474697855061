import { Box } from '@mui/material';
import TherapyCard, { TherapyContent } from '../TherapyCard';
import img1 from "./pic/바람은 보이지 않아.jpeg";
import img2 from "./pic/20240827_눈물바다.jpg";
import img3 from "./pic/20240827_내 이름은 자가주.jpg";
import img4 from "./pic/20240827_내 친구 월터.jpg";
import img5 from "./pic/20240827_천만의 말씀.jpg";
import img6 from "./pic/숲속에서.jpeg";
import img7 from "./pic/나는 요,.jpeg";

const therapyContent: TherapyContent[] = [
    {
      imagePath: img1,
      title: "바람은 보이지 않아",
      author: "안 에르보 글·그림/ 김벼리 옮김/ 한울림 어린이",
      description: ['소년은 보이지 않고 들리지 않는 바람의 색깔을 찾아 산책을 떠납니다.',
        '개, 늑대, 코끼리, 나무들에게 바람의 색깔이 무엇인지 물어보면서, 바람의 색깔은 ‘숲속에 깔린 젖은 흙이 품고 있는 어둠의 색이고, 나를 어루만지는 파란 구름색’이라고 이들은 속삭이듯 말해줍니다.',
        '각 장마다 다른 그림들이 있고 그 속에 투명하게 숨어있는 사물과 동물, 자연물들을 있습니다.', 
        '그림을 손으로 만져보면 형체가 드러나서 재미와 즐거움을 느끼게 됩니다.',
        '왼쪽 페이지 밑에 있는 검은 장화가 소년이 산책을 하고 있다고 암시해 줍니다.',
        '자연과 일치되고 있다는 느낌과 함께 나의 내면으로 스며들어가고 있다는 느낌을 받을 수 있습니다.',
        '혼자라는 불안감으로 힘들어하는 사람들에게 누군가가 함께 머물고 있음을 인식하는 것만으로도 안정감을 얻게 해주는 도서입니다.']
    },
    {
        imagePath: img2,
        title: "눈물 바다",
        author: " 서현 글·그림/ 사계절",
        description: ['내 마음대로 되지 않는 날.',
            '마음은 눈물로 가득 차 있고 서럽고 외롭기만 합니다.',
            '방으로 들어온 주인공은 눈물바다에 빠져듭니다.',
            '둥둥 떠다니는 인형, 옷, 책상, 물고기, 사람 … ….',
            '눈물이라는 바다에 빠져있는 주인공처럼 독자도 주인공과 함께 자신의 눈물바다에 빠져 보게됩니다.',
            '주인공의 눈에 눈물로 가득차 가는 과정이 작은 그림에서 점점 큰 그림으로 보여집니다.',
            '그 과정은 눈물이라는 바다로 독자를 끌고 갑니다.',
            '출렁이는 바다가 3면으로 펼쳐지는 페이지도 있어서 눈물로 정화되어가고 있는 주인공의 마음을 볼 수 있어요.',
            '마지막에 주인공은 드라이기로 눈물바다에 빠진 것들을 말려 줍니다.', 
            '후련하게! 아이들에게 언제 눈물이 나는지 물어보고 아이들의 속상한 마음 속으로 함께 들어가 보세요.']
      },
    {
      imagePath: img3,
      title: "내 이름은 자가주",
      author: "퀜틴 블레이크 글·그림/ 김경미 옮김/ 도서출판 마루벌",
      description: ['아기가 태어나 성인으로 성장해 가는 과정에서 발달 시기에 나타나는 독특한 모습을 동물들의 특성으로 찾아낼 수 있어요.',
        '변해가는 동물들의 모습에 부모들은 깜짝, 깜짝 놀랍니다.', 
        '어른이 된 자가주는 아이들이 보게 되는 자신의 미래의 모습입니다.',
        '나는 지금 어떤 동물의 모습일까를 상상해 볼 수 있고, 어떤 때 멧돼지나 못된 용, 낯선 털복숭이가 되는지도 생각해볼 수 있어요.',
        '미래에 나는 어떤 모습이 되고 싶은지 상상해 볼 수 있어요. ']
    },
    {
      imagePath: img4,
      title: "내 친구 월터",
      author: "안나 워커 글·그림/ 김경연 옮김/ 재능교육",
      description: [' 로티에게는 비밀이 있어요. 수영장에 가서 물 속에 들어가지 못하는….',
        '수영장에 상어가 있거든요.',
        '매일 수영장 안으로 들어가지 못하던 어느 날 월터가 찾아왔어요.',
        '로티는 월터가 부르는 ‘훔벨리 두, 룸벨리 라, 루피 루!’ 마법 주문을 외우며 물 속에 들어갈 수 있게 돼요.',
        '용기를 부르는 마법의 주문을 여러분도 함께 외워봐요.']
    },

    {
      imagePath: img5,
      title: "천만의 말씀",
      author: "스즈키노리타케 글·그림 / 김 숙 옮김/ 북 뱅크",
      description: ['남들이 가지고 있는 것이 부러워 보이고 좋아보이기도 해요.',
        '그렇지만 본인은 남들이 부러워하는 것이 불편하고 걱정거리가 되기도 합니다.',
        '코뿔소는 갑옷이 무거워 죽겠고, 토끼는 너무 잘 뛰어서 곤란하고, 기린은 목이 길어서 열매 따기는 좋지만 얼굴 씻기도 어려워요.',
        '내가 가진 좋은 능력은 어떤 게 있을까 생각해 보고, 어떤 게 있었으면 좋을지 생각해 보세요.',
        '나만이 가진 자랑거리는 아이들의 자존감을 높여주고, 자긍심을 갖게 합니다.',
        '갖고 싶은 능력은무엇인지 생각해 보고 왜 그것이 갖고 싶은지 말하게 하면 아이들이 현재 바라는 욕구를 알 수 있어요.']
    },
    {
      imagePath: img6,
      title: "숲 속에서",
      author: "클레어 A. 니볼라 글·그림 /김기택 옮김/ 비룡소",
      description: ['미지의 세계를 두려워하는 아이에게 용기를 주는 동화에요.',
        '삽화가 페이지의 전체를 차지하는 곳도 있지만 숲과 마을의 거리감을 주기 위해 빈 공간을 두고 있어요.',
        '이 빈 공간은 주인공이 자기가 살던 곳에서 무서움을 이겨내고 먼 곳으로 가는 용기가 있음을 암시해 줍니다.',
        '주인공은 숲의 안전한 공간에 누워서 편안함을 느끼며, 하늘의구름을 바라보고, 푸른 숲을 바라봅니다.',
        '독자들도 주인공과 함께 그 공간에 있는 것 같은 느낌을 받을 수 있어요.',
        '아이가 무서워하고 두려워하는 것이 무엇인지 생각해 보게 하고, 그것에 도전하기 위해 용기를 내보자고 격려해줄 수 있어요.',
        '두려움이 많은 아이에게 용기를 갖게 해주는 동화에요.']
    },
    {
        imagePath: img7,
        title: "나는 요,",
        author: "김희경 글/여유당",
        description: ['동물들의 특성을 보며 내 안에 있는 여러 모습들을 떠올려 볼 수 있어요.',
            '작은 것에도 깜짝 깜짝 놀라는 나의 모습이 있고, 자신만의 공간에 있을 때 편안함을 느끼는 나가 있어요.',
            '그리고 새로운 도전을 할 때는 항상 온몸이 떨리는 나가 있지요.',
            '나에 대해 알고 싶은 모든 이에게 자기를 사랑하게 하는 마법을 갖게 해요.', 
            '맑고 투명한 느낌을 주는 삽화들이 마음을 따스하게 감싸주는 듯해요.',
            '동물들의 발자국이 누구의 것인지 찾아볼 수 있고, 구름에 숨어있는 동물은 누구인지, 그리고 표정에서 어떤 마음 상태인지 맞춰보는 활동을 할 수 있어요.',
            '나와같은 특징을 가진 동물들을 찾아보아도 좋아요.']
    },
  ]
  

const SelfEsteemContent: React.FC = () => {
    return <Box sx={{ 
        display: 'block',
      }}>
        {therapyContent.map((e, index) => (
          <TherapyCard key={index} title={e.title} author={e.author} imagePath={e.imagePath} description={e.description}/>))}
      </Box>
}

export default SelfEsteemContent;
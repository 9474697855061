import './Story.css';
import StoryBoard, { AccordionContent } from './StoryBoard';
import Therapy from './Therapy/Therapy';

const notice: AccordionContent[] = [
  {
    panel: 'panel1',
    title: '애착 관계 형성 부모-자녀 독서 프로그램',
    content: ['시간:90분', '대상: 유치원 자녀와 어머니', '그림책을 유아와 엄마가 함께 읽고 나누는 시간을 가지며, 책의 내용과 관련된 활동을 하고, 신체적 움직임과 마음의 대화를 통해 안정적인 애착관계를 형성할 수 있게 합니다.']
  },
  {
    panel: 'panel2',
    title: '부모긍정훈육 집단상담 프로그램',
    content: ['시간: 90분', '대상: 자녀와 행복한 관계 형성을 원하는 부모', '부모는 초기기억을 통해 자신을 이해하고, 자녀의 생활양식을 파악하여 자녀의 행동을 이해하게 되면서 행복한 부모자녀 관계를 형성하게 됩니다.']
  },
  {
    panel: 'panel3',
    title: "'우리 자녀 함께 키우기' 무료상담 프로그램",
    content: ['날짜: 매월 둘째 주 수요일 오전 11시', '장소: 단비심리상담연구소', '신청: 참가 전에 전화 또는 문자 주세요.', '대상: 내가 아이를 잘 키우고 있는지 걱정되시는 부모님. 자녀와 대화의 어려움을 겪고 계시는 부모님. 자녀 양육에서 좌절감과 실망을 느끼시는 부모님.']
  }
]

const qna: AccordionContent[] = [
  {
    panel: 'panel1',
    title: '유아도 심리 검사할 수 있나요?',
    content: ['그림을 그릴 수 있는 유아라면 심리검사를 할 수 있어요.',
      '집, 나무, 사람 검사와 동적 가족화(HTP, KFD 검사) 그리기를 통해 아동의 성격과 심리를 알 수 있어요.',
      '그림을 그리는 동안 아이가 보이는 행동과 태도에서 아이의 부적응적 행동을 알아낼 수 있어요.']
  },
  {
    panel: 'panel2',
    title: '아이의 문제가 되는 행동을 치료할 수 있나요?',
    content: ['문제가 되는 아이의 행동에는 그 이면에 아이가 원하는 욕구가 있어요.',
      '그러나 그것을 말로 아이 스스로 표현해내는 데는 어려움이 있어요.',
      '놀이를 하면서 아이가 가진 내면의 욕구를 표출하도록 돕거나, 그림책을 읽으면서 자연스럽게 꺼내놓을 수 있도록 하여 치료를 합니다.']
  },
  {
    panel: 'panel3',
    title: '내가 심리상담이 필요할까요?',
    content: ['심리상담은 누구에게나 필요합니다.',
      '그렇지만 심리상담에 익숙하지 않아서 그냥 묻어두고 지내는 경우가 많습니다.',
      '신체에 병이 나면 병원에 찾아가지만, 마음에 찾아오는 감정에 의한 상처는 그냥 지나칩니다.',
      '다음과 같은 경험이 있는지 살펴보세요.',
      '♤ 친구들이나 직장 동료들과 이야기를 나눌 때 그 자리에 앉아 있는 게 불편하고, 얼른 일어나고 싶어요.',
      '♤ 동생이 화를 내며 문을 꽝 닫으면 ‘내가 뭘 잘못했지?’ 하는 생각에 아무 말도 못하고 혼자서 울어요.',
      '♤ 시어머니가 반찬을 자주 만들어서 갖다 주시는데 한번 먹으면 없을 정도로 너무 조금씩 해주세요. 저는 어머니 손맛이 좋아 많이 먹고 싶거든요. 그런데 많이 해달라고 못하겠어요. 내가 먹는 것만 좋아하는 둔하고, 미련하고, 지혜롭지 못한 사람으로 보일 것 같아서요. 어른들은 먹는 것 좋아하면 미련하다고 생각하시잖아요.',
      '♤ 고1 여학생이에요. 저는 공부를 못해서인지 친구들과 얘기할 때 끼어들기가 어려워요. 반아이들이 저와 친하게 지내는 것처럼 하지만 겉으로만 잘 지내고 속 깊은 얘기를 나누는 게 없어요. 그리고 진짜 중요한 것에는 저를 끼워주지 않아요. ',
      '♤ 아이가 혼자서 놀지 않고 엄마랑만 놀려고 해요. 자주 떼를 쓰고, 고집부리고. 유치원에서는 친구들을 때리는 일이 많아요. ',
      '♤ 저는 열심히 공부한다고 저 스스로도 생각하고 주변에서도 열심히 한다고 해요. 그런데 성적이 안 올라요. 어떻게 해야 성적이 오를지 모르겠어요. 시험 기간에는 잠도 잘 수 없고, 밥도 먹을 수 없을 정도로 불안해요.']
  },
]

const Story: React.FC = () => {    
    return (
    <section id="story" className='story-body'>
        <h2>단비 스토리</h2>
        <Therapy />
        <StoryBoard title='공지사항' contents={notice}/>
        <StoryBoard title='Q & A' contents={qna}/>
    </section>
)
};

export default Story;
import Navigation from "../../common/Navigation";
import InternetOveruseDiagnosisMarker from "./InternetOveruseDiagnosisMarker";

const questions = [
    "인터넷 사용으로 인해서 생활이 불규칙해졌다. ",
    "인터넷 사용으로 건강이 이전보다 나빠진 것 같다.",
    "인터넷 사용으로 학교 성적이 떨어졌다.",
    "인터넷을 너무 사용해서 머리가 아프다. ",
    "인터넷을 하다가 계획한 일들을 제대로 못한 적이 있다.",
    "인터넷을 하느라고 피곤해서 수업시간에 잠을 자기도 한다.",
    "인터넷을 너무 사용해서 시력 등에 문제가 생겼다. ",
    "다른 할 일이 많을 때에도 인터넷을 사용하게 된다.",
    "인터넷 사용으로 인해 가족들과 마찰이 있다. ",
    "인터넷을 하지 않을 때에도 하고 있는 듯한 환상을 느낀 적이 있다",
    "인터넷을 하지않을 때에도 인터넷에서 나오는 소리가 들리고 인터넷을 하는 꿈을 꾼다.",
    "인터넷 사용 때문에 비도덕적인 행위를 저지르게 된다.",
    "인터넷을 하는 동안 나는 가장 자유롭다. ",
    "인터넷을 하고 있으면 기분이 좋아지고 흥미진진해진다.",
    "인터넷을 하는 동안 나는 더욱 자신감이 생긴다.",
    "인터넷을 하고 있을 때 마음이 제일 편하다.",
    "인터넷을 하면 스트레스가 모두 해소되는 것 같다.",
    "인터넷이 없다면 내 인생에 재미있는 일이란 없다.",
    "인터넷을 하지 못하면 생활이 지루하고 재미가 없다. ",
    "만약 인터넷을 다시 할 수 없게 된다면 견디기 힘들 것이다.",
    "인터넷을 하지 못하면 안절부절 못하고 초조해진다.",
    "인터넷을 하고 있지 않을 때에도 인터넷에 대한 생각이 자꾸 떠오른다.",
    "인터넷 사용 때문에 실생활에서 문제가 생기더라도 인터넷 사용을그만두지 못한다.",
    "인터넷을 할 때 누군가 방해를 하면 짜증스럽고 화가 난다.",
    "인터넷에서 알게 된 사람들이 현실에서 아는 사람들보다 나에게 더 잘해준다",
    "온라인에서 친구를 만들어 본 적이 있다.",
    "오프라인에서보다 온라인에서 나를 인정해 주는 사람이 더 많다.",
    "실제에서 보다 인터넷에서 만난 사람들을 더 잘 이해하게 된다.",
    "실제상황에서도 인터넷에서 하는 것처럼 해보고 싶다.",
    "인터넷 사용 시간을 속이려고 한 적이 있다.",
    "인터넷을 하느라고 수업에 빠진 적이 있다.",
    "부모님 몰래 인터넷을 한다. ",
    "인터넷 때문에 돈을 더 많이 쓰게 된다. ",
    "인터넷에서 무엇을 했는지 숨기려고 한 적이 있다.",
    "인터넷에 빠져 있다가 다른 사람과의 약속을 어긴 적이 있다.",
    "인터넷을 한번 시작하면 생각했던 것보다 오랜 시간 인터넷에서 보내게 된다.",
    "인터넷을 하다가 그만 두면 또 하고 싶다.",
    "인터넷 사용시간을 줄이려고 해 보았지만 실패한다.",
    "인터넷 사용을 줄여야 한다는 생각이 끊임없이 들곤 한다.",
    "주위 사람들이 내가 인터넷을 너무 많이 한다고 지적한다."
];

const InternetOveruseDiagnosis: React.FC = () => {
    return <div>
        <Navigation />
        <InternetOveruseDiagnosisMarker
        questions={questions} />
    </div>;
}

export default InternetOveruseDiagnosis;